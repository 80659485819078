import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import EstimFinaliste from '../../assets/svg/awards/estimFinalist.svg'
import Estim from '../../assets/svg/awards/estim.svg'
import Affaires from '../../assets/svg/awards/affaires.svg'

const AwardGridCard = ({ label }) => {
    const { t } = useTranslation('misc')
    return (
        <div className="card awardGridCard">
            <h3 className="cardTitle">{label || t('awardGridTitle')}</h3>
            <div className="logoGrid">
                <EstimFinaliste />
                <Estim />
                <Affaires />
            </div>
        </div>
    )
}

export default AwardGridCard

AwardGridCard.propTypes = {
    label: PropTypes.string,
}

AwardGridCard.defaultProps = {
    label: '',
}

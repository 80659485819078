import React, { forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Layout from '../components/layout'
import SEO from '../components/seo'
// import OutlinedButton from '../components/outlinedButton/OutlinedButton'
import LogoGridCard from '../components/cards/LogoGridCard'
import ContactPreview from '../components/cards/ContactPreview'
import OutlinedButton from '../components/outlinedButton/OutlinedButton'
import { About, JMSM } from '../utils/navLinks'
import Link from '../components/link'
import AwardGridCard from '../components/cards/AwardGridCard'

import Logo from '../assets/svg/logo.svg'
import JMSMOverview from '../assets/svg/jmsmOverview.svg'
import DNAMagnifier from '../assets/svg/dnaMagnifier.svg'

function IndexPage() {
    const { t } = useTranslation('homepage')
    const value = 'Je mise sur moi'
    return (
        <Layout>
            <SEO title="homepage:title" description="homepage:description" />
            <div className="homeHeader">
                <Logo className="paLogo" />
            </div>
            <div className="cardGrid">
                <div className="card jmsmCard">
                    <h3 className="cardTitle">{t('jmsm:previewCardTitle')}</h3>
                    <p className="cardParagraph">
                        <Trans i18nKey="jmsm:previewCardDescription" value={value}>
                            Empower your teen with <i>{{ value }}</i>. Discover academic and career
                            guidance done differently.
                        </Trans>
                    </p>
                    <OutlinedButton
                        label={t('misc:discover')}
                        className="discoverButton"
                        to={JMSM.path}
                        // eslint-disable-next-line no-unused-vars
                        component={forwardRef((props, ref) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Link {...props} />
                        ))}
                    />
                    <JMSMOverview />
                </div>
                <div className="card aboutCard">
                    <h3 className="cardTitle">{t('about:title')}</h3>
                    <p className="cardParagraph">{t('about:previewCardDescription')}</p>
                    <OutlinedButton
                        label={t('misc:discover')}
                        className="discoverButton"
                        to={About.path}
                        // eslint-disable-next-line no-unused-vars
                        component={forwardRef((props, ref) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Link {...props} />
                        ))}
                    />
                    <DNAMagnifier />
                </div>
            </div>
            <AwardGridCard />
            <LogoGridCard />
            <ContactPreview />
        </Layout>
    )
}

export default IndexPage
